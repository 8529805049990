/**
 * @Description: 两位数的乘法（不丢失精度）
 * @Author: Niklaus
 * @Date: 2022-07-04 09:23:24
 * @param {*} a
 * @param {*} b
 */
//  const add = (a, b) => {
//   if(!a) a=0
//   if(!b) b= 0
//   let t1 = a.toString(), t2= b.toString();
//   let m= t1.split(".")[1] ? t1.split(".")[1].length : 0
//   let n= t2.split(".")[1] ? t2.split(".")[1].length : 0
//   let e = m > n ? m : n
//   let total = Number(t1) * Math.pow(10, e) + Number(t2) * Math.pow(10, e)
//   return total / Math.pow(10, e)
// }

const add = (a, b) => {
  let t1 = a.toString(), t2= b.toString();
  let n1 = t1.split(".")[1] ? t1.split(".")[1].length : 0
  let n2 = t2.split(".")[1] ? t2.split(".")[1].length : 0
  let n = n1 >=n2 ? n1 : n2
  return (Number((multiplication(a,Math.pow(10, n))) + (multiplication(b, Math.pow(10, n)))) / Math.pow(10, n));
}

/**
 * @Description: 两位数的乘法（不丢失精度）
 * @Author: Niklaus
 * @Date: 2022-07-04 09:23:24
 * @param {*} a
 * @param {*} b
 */
const multiplication = (a, b) => {
  let m = 0, t1 = a.toString(), t2= b.toString();
  m+= t1.split(".")[1] ? t1.split(".")[1].length : 0
  m+= t2.split(".")[1] ? t2.split(".")[1].length : 0
  return Number(t1.replace(".","")) * Number(t2.replace(".","")) / Math.pow(10, m)
}

/**
 * @Description: 数字 num  保留 n 位小数
 * @Author: Niklaus
 * @Return:
 * @Date: 2022-07-04 09:38:09
 * @param {*} num
 * @param {*} n
 */
const toNumFix = (num, n) => {
  let t = num.toString().split(".")
  if(!t[1] || t[1].length <= n)
    return num
  else  {
    return Math.round(Number(num) * Math.pow(10, n)) / Math.pow(10, n)
  }
}

export {
  multiplication,
  toNumFix,
  add
}
